import swal from 'sweetalert';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getLink, links } from './urls';

const UseToken = () => {
	const isLoggedIn = 'ptl_powered_moneytrust_mfb_admin_is_logged';
	const accessToken = 'ptl_powered_moneytrust_mfb_admin_accessToken';
	const userData = 'ptl_powered_moneytrust_mfb_admin_other_data';

	function logUserIn() {
		localStorage.setItem(isLoggedIn, true);
	}
	function isUserLogged() {
		const prefs = localStorage.getItem(isLoggedIn);
		return prefs;
	}
	function signOut() {
		localStorage.clear();
	}

	function setAccessToken(value) {
		localStorage.setItem(accessToken, value);
	}
	function getAccessToken() {
		const prefs = localStorage.getItem(accessToken);
		return prefs;
	}

	function setUserData(value) {
		localStorage.setItem(userData, value);
	}
	function getUserData() {
		const prefs = localStorage.getItem(userData);
		return prefs;
	}

	async function getRequest(link, load = true) {
		if (load) {
			NProgress.start();
		}
		const response = await fetch(link, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
		}).then((data) => data.json());
		if (load) {
			NProgress.done();
		}
		if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function putRequest(link, params) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
			body: JSON.stringify(params),
		}).then((data) => data.json());
		NProgress.done();
		if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function patchRequest(link, params) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
			body: JSON.stringify(params),
		}).then((data) => data.json());
		NProgress.done();
		if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function postRequest(link, params) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
			body: JSON.stringify(params),
		}).then((data) => data.json());
		NProgress.done();
		if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function deleteRequest(link) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
		}).then((data) => data.json());
		NProgress.done();
		if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function logoutNow() {
		NProgress.start();
		const response = await fetch(getLink(links.pages.logout), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
		}).then((data) => data.json());
		NProgress.done();
		if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	function logoutUser() {
		swal('Are you sure you want to sign out?', {
			buttons: {
				cancel: 'Stay logged In!',
				catch: {
					text: 'Yes, I am!',
					value: 'catch',
				},
			},
		}).then(async (value) => {
			if (value === 'catch') {
				const response = await logoutNow();
				if (response.status) {
					localStorage.clear();
					window.location.href = '/login';
				} else {
					swal('Oops!', response.message, 'error');
				}
			}
		});
	}

	async function getUserDataBg(reload) {
		const response = await UseToken().getRequest(getLink(links.pages.profile), false);
		if (response.status) {
			UseToken().setUserData(JSON.stringify(response.data));
			if (reload) {
				window.location.reload(false);
			}
		} else if (response.message && response.message.toLowerCase() === 'unauthenticated.') {
			UseToken().signOut();
		}
	}

	return {
		isUserLogged,
		logUserIn,
		signOut,
		setAccessToken,
		getAccessToken,
		setUserData,
		getUserData,
		logoutUser,
		getRequest,
		putRequest,
		patchRequest,
		postRequest,
		deleteRequest,
		getUserDataBg,
	};
};
export default UseToken;
