export const links = {
	// bearerUrl: 'https://moneytrust.test/api/v1',
	// galleryUrl: 'https://moneytrust.test/storage',
	bearerUrl: 'https://backend.pubsec.moneytrustmfb.com/api/v1',
	galleryUrl: 'https://backend.pubsec.moneytrustmfb.com/storage',
	pages: {
		login: 'auth/login',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		profile: 'admin/profile',
		logout: 'logout',
		loans: 'admin/loans',
		processLoan: 'admin/loans/processLoan',
		offers: 'admin/offers',
		repayments: 'admin/repayments',
		liquidate: 'admin/loans/liquidate/loan',
		markAspaid: 'admin/loans/markAsPaid/loan',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		agents: 'admin/agents',
		customers: 'admin/customers',
		approval: 'admin/agents/approval',
		perm: 'admin/users/perm',
		notifications: 'admin/notifications',
		roles: 'admin/roles',
		permissions: 'admin/permissions',
		invite_links: 'admin/invite_links',
		administrators: 'admin/administrators',
		admin_invite: 'admin/create',
		verify_invite_link: 'admin/invite/verify',
		join_team: 'admin/administrators/store',
		profile_update: 'profile/update',
		loanReport: 'admin/reports/loan_report',
		customerReport: 'admin/reports/customer_report',
		dashboard: 'admin/dashboard',
		importLoan: 'admin/imports/loan-imports',
		importRepayment: 'admin/imports/repayment-imports',
		importHistory: 'admin/imports/history',
		reverseImport: 'admin/imports/reverse_import',
	},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
